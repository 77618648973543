import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IChampionItem, ChampionParams } from '@pig-casino/types/Champion.type'

async function list(
  params: ChampionParams,
): Promise<CommonResponse<IChampionItem[]>> {
  return await api.get('/v1/loyalty/casino/bigwin-stack', {
    params: {
      ...params,
    },
  })
}

export const key = {
  list: 'LIST_CHAMPIONS',
}

export { list }
