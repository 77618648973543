import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IAuthState } from '@pig-common/types/Authentication.type'
import {
  IRedeemResponseData,
  IVIPResponseData,
} from '@pig-common/types/Vip.type'
import { IVIPStatusData } from '@pig-common/types/Vip.type'

async function get(): Promise<CommonResponse<IVIPResponseData[]>> {
  return await api.get('/v1/loyalty/vip/')
}

export interface IGetStatusParams {
  customerCode?: string
}

type IVIPResponseTierTypes =
  | 'Bronze Level'
  | 'Silver Level'
  | 'Gold Level'
  | 'Diamond Level'
export interface IVIPStatusResponseData
  extends Omit<IVIPStatusData, 'userTier'> {
  userTier?: IVIPResponseTierTypes
}

async function getStatus({
  customerCode,
}: IGetStatusParams): Promise<CommonResponse<IVIPStatusResponseData>> {
  return await api.get(`/v1/loyalty/${customerCode}/vip/status`)
}

interface IVIPSummaryResponseData {
  nextVip?: Array<{
    icon?: string
    level?: string
    tier?: IVIPResponseTierTypes
    detail?: string[]
  }>
  currentVip?: {
    icon?: string
    level: string
    tier: IVIPResponseTierTypes
    detail: string[]
  }
  vipMaster?: Array<{
    tierIcon?: string
    tier?: IVIPResponseTierTypes
    levels?: Array<{
      vipMasterUid?: string
      level?: string
      accTurnover?: number
      star?: number
    }>
  }>
}

interface IGetSummaryParams {
  customerCode?: string
}

async function getSummary({
  customerCode,
}: IGetSummaryParams): Promise<CommonResponse<IVIPSummaryResponseData>> {
  return await api.get(`/v1/loyalty/${customerCode}/vip/summary`)
}

interface IRedeemRewardParams {
  customerCode?: string
  rewardUID: string
}

async function redeemReward({
  customerCode,
  rewardUID,
}: IRedeemRewardParams): Promise<CommonResponse<IRedeemResponseData>> {
  return await api.post(
    `/v1/loyalty/${customerCode}/vip/redeem?vip_master_uid=$`,
    {},
    {
      params: {
        vip_master_uid: rewardUID,
      },
    },
  )
}

// TODO : this api use on guild member page
async function postVipMember(
  auth: IAuthState,
): Promise<CommonResponse<unknown>> {
  return await api.post('/v1/loyalty/vip/members', {
    customer_code: auth.customerCode,
  })
}

export const key = {
  get: 'GET_VIP',
  getStatus: 'GET_VIP_STATUS',
  getSummary: 'GET_VIP_SUMMARY',
  redeemReward: 'REDEEM_VIP_REWARD',
}

export { get, getStatus, getSummary, redeemReward, postVipMember }
