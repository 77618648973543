import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IProfileData } from '@pig-common/types/Profile.type'

export interface IGetProfileParams {
  queryBy?:
    | 'ID_CARD'
    | 'USER_CODE'
    | 'PHONE_NUMBER'
    | 'BANK_ACCOUNT'
    | 'TRUE_WALLET'
  idCard?: string
  userCode?: string
  phoneNumber?: string
  phoneNumberPattern?: string
  idCardPattern?: string
  backIdCardPattern?: string
  bankAccountNumber10DigitsPattern?: string
  bankAccountNumber12DigitsPattern?: string
}

export interface IGetProfileHeaders {
  authorisation?: string
}

async function get(
  params: IGetProfileParams,
  headers?: IGetProfileHeaders,
): Promise<CommonResponse<IProfileData>> {
  return await api.get('/v1/user/me/profile', {
    ...(headers && {
      headers: {
        authorisation: headers?.authorisation,
      },
    }),
    params: {
      query_by: params?.queryBy || 'USER_CODE',
      phone_number_pattern: '********00',
      id_card_pattern: '*********0000',
      back_id_card_pattern: '**********00',
      bank_account_number_10_digits_pattern: '******0000',
      bank_account_number_12_digits_pattern: '********0000',
      phone_number: params?.phoneNumber,
      user_code: params.userCode,
      ...params,
    },
  })
}

export const key = {
  get: 'GET_PROFILE',
}

export { get }
