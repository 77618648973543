import { authStateCommon, profileStateCommon } from '@pig-common/recoils'
import { useQuery } from '@tanstack/react-query'
import { profileApi } from '@pig-common/api'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IProfileData } from '@pig-common/types/Profile.type'

interface IProfileHook {
  params?: profileApi.IGetProfileParams
  select?: (data: CommonResponse<IProfileData>) => IProfileData
  enabled?: boolean
}

export default function useProfile({ params, select, enabled }: IProfileHook) {
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const { userUID } = useRecoilValue(authStateCommon.authState)
  const setProfileState = useSetRecoilState(profileStateCommon.profileState)

  return useQuery({
    queryKey: [profileApi.key.get, params],
    queryFn: () => profileApi.get({ userCode: userUID, ...params }),
    enabled: !isAuthenticated ? false : enabled,
    select: (data) => (select ? select(data) : data?.data),
    onSuccess: (data: IProfileData) => {
      setProfileState(data)
    },
  })
}
