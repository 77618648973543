import { CSSProperties, ReactNode, createElement } from 'react'
import cx from 'classnames'
import styles from './index.module.scss'

type TitleType = {
  h1?: string
  h2?: string
  h3?: string
  h4?: string
  h5?: string
  h6?: string
}

interface SectionProps {
  title?: string | TitleType
  subTitle?: string
  children?: ReactNode
  className?: string
  sectionClassName?: string
  extra?: ReactNode
  padding?: boolean
  margin?: boolean
  style?: CSSProperties
}

export function Section({
  title,
  subTitle,
  children,
  extra,
  className,
  sectionClassName,
  margin = true,
  padding = true,
  style,
}: SectionProps) {
  return (
    <section
      className={cx(styles.container, sectionClassName, {
        [styles['no-padding']]: !padding,
        [styles['no-margin']]: !margin,
      })}
      style={style}
    >
      {title && (
        <div
          className={cx(
            styles.header,
            { [styles.extra]: extra },
            { [styles.padding]: !padding },
          )}
        >
          {typeof title === 'string' ? (
            <h4 className="title">{title}</h4>
          ) : (
            createElement(
              Object.keys(title)?.[0],
              {},
              // @ts-ignore
              title?.[Object.keys(title)?.[0]],
            )
          )}

          {extra}
        </div>
      )}
      {subTitle && <span>{subTitle}</span>}
      <div
        className={cx(
          styles.body,
          { [styles['no-margin']]: !margin },
          className,
        )}
      >
        {children}
      </div>
    </section>
  )
}
