import { Button, IHeaderProps } from '@pig-frontend/uikit'
import { Badge, Dropdown } from 'antd'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import Balance from '@pig-casino/components/balance'
import { NavigationBarPigSpinIcon } from '@pig-casino/constants/images'
import Link from 'next/link'
import { DownOutlined } from '@ant-design/icons'
import styles from '../index.module.scss'

const items = ({ handleSwitch }) => [
  {
    key: 'pigspin',
    label: (
      <div
        role="button"
        aria-hidden
        className={styles['dropdown-menu']}
        onClick={handleSwitch}
      >
        <NavigationBarPigSpinIcon />
        <span>สล็อต</span>
      </div>
    ),
  },
]

interface IHeaderMenuProps {
  handleSwitch?: () => void
  hasNotiUnread?: boolean
}

export const headerMenus = ({
  handleSwitch,
  hasNotiUnread,
}: IHeaderMenuProps): IHeaderProps['menus'] => [
  {
    key: 'promotions',
    render: (
      <Link href={pageLinkCommon.promotions}>
        <Badge>
          <span>โปรโมชั่น</span>
        </Badge>
      </Link>
    ),
    showOnDesktop: true,
    hideMenuDesktopOnNonPaged: true,
  },
  {
    key: 'notifications',
    render: (
      <Link href={pageLinkCommon.notifications}>
        <Badge dot={hasNotiUnread}>
          <span>การแจ้งเตือน</span>
        </Badge>
      </Link>
    ),
    showOnDesktop: true,
    hideMenuDesktopOnNonPaged: true,
  },
  {
    key: 'app-switcher',
    render: (
      <Dropdown
        menu={{ items: items({ handleSwitch }) }}
        overlayClassName={styles['overlay-dropdown']}
      >
        <div className={styles['app-switcher-container']}>
          <span>คาสิโน</span>
          <DownOutlined className={styles['down-icon']} />
        </div>
      </Dropdown>
    ),
    showOnDesktop: true,
    hideMenuDesktopOnNonPaged: true,
  },
  {
    key: 'balance',
    render: () => <Balance />,
    showOnNonPaged: true,
  },
  {
    key: 'wallet',
    render: ({ router }) => (
      <Button
        type="primary"
        onClick={() => router.push(pageLinkCommon.wallet)}
        className={styles['menu-desktop']}
      >
        เติมเงิน
      </Button>
    ),
    showOnDesktop: true,
    hideMenuDesktopOnNonPaged: true,
  },
]
