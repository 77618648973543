import qs from 'qs'
import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'
import {
  IGameLobby,
  IGameItem,
  IGameEntry,
  IGameFavorite,
  IGameBrands,
} from '@pig-casino/types/Game.type'
import { SearchParamType } from '@pig-casino/types/Search.type'

export interface IListProps extends SearchParamType {
  customerCode?: string
}

export async function list({
  gameType,
  filterType,
  favorite,
  dealer,
  customerCode,
  ...params
}: IListProps): Promise<CommonResponse<IGameItem[]>> {
  const paramsString = qs.stringify(
    {
      filter_favorite_game: favorite,
      type_keywords: filterType,
      product_types: gameType,
      language: dealer,
      customer_code: customerCode,
      ...params,
    },
    { arrayFormat: 'repeat', addQueryPrefix: true },
  )

  return await api.get(`/v1/game/casino/${paramsString}`)
}

export interface IGameEntryParams {
  customerCode: string
  gameToken: string
  tableId: string
}

export async function gameEntry({
  customerCode,
  gameToken,
  tableId,
}: IGameEntryParams): Promise<CommonResponse<IGameEntry>> {
  return await api.post('/v1/game/casino/evo-game-entry', {
    player_name: customerCode,
    game_token: gameToken,
    table_id: tableId,
  })
}

export interface IGameLobbyParams {
  provider: string
  customerCode: string
  gameToken: string
  isMobile?: boolean
}

export async function gameLobby({
  provider,
  customerCode,
  gameToken,
  isMobile = true,
}: IGameLobbyParams): Promise<CommonResponse<IGameLobby>> {
  return await api.post('/v1/game/casino/lobby', {
    provider: provider,
    player_name: customerCode,
    game_token: gameToken,
    is_mobile: isMobile,
  })
}

export async function updateFavorite({
  customerCode,
  gameId,
  isFavorite,
}: IGameFavorite): Promise<CommonResponse<IGameFavorite>> {
  return await api.put('/v1/user/casino/update_favorite_game', {
    customer_code: customerCode,
    game_code: gameId,
    game_id: gameId,
    is_favorite: isFavorite,
  })
}

export async function getByGameId(gameId): Promise<CommonResponse<IGameItem>> {
  return await api.get(`/v1/game/casino/${gameId}`)
}

export async function getByGameName(
  gameName,
): Promise<CommonResponse<IGameItem>> {
  return await api.get(`/v1/game/casino/game-name/${gameName}`)
}

export async function getBrands(): Promise<CommonResponse<IGameBrands[]>> {
  return await api.get('/v1/game/brand/?party_brand=PIGBET')
}

export const key = {
  list: 'LIST_GAME',
  lobby: 'LOBBY',
  popular: 'POPULAR_GAME',
  recent: 'RECENT_GAME',
  favorite: 'FAVORITE_GAME',
  highlight: 'HIGHLIGHT_GAME',
  gameEntry: 'GAME_ENTRY',
  updateFavorite: 'UPDATE_FAVORITE',
  getByGameId: 'GET_BY_GAME_ID',
  getByGameName: 'GET_BY_GAME_NAME',
  getBrands: 'GET_BRANDS',
}
